import React, { useState, useEffect, useRef } from "react";
import "./Slideshow.css";
import Arrow1 from "../../assets/Arrow1.png";
import Arrow2 from "../../assets/Arrow2.png";
import Arrow3 from "../../assets/Arrow3.png";
import Circle1 from "../../assets/Circle1.png";

const Slideshow = ({ images, labels }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [elapsedTime, setElapsedTime] = useState(0);
  const intervalRef = useRef(null);

  const SLIDE_DURATION = 7500;

  useEffect(() => {
    if (isPlaying) {
      const startTime = Date.now() - elapsedTime;
      intervalRef.current = setInterval(() => {
        const timePassed = Date.now() - startTime;
        setElapsedTime(timePassed);
        if (timePassed >= SLIDE_DURATION) {
          setElapsedTime(0);
          setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }
      }, 100);
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [isPlaying, elapsedTime, images.length]);

  const goToPreviousSlide = () => {
    setIsPlaying(false);
    setElapsedTime(0);
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const goToNextSlide = () => {
    setIsPlaying(false);
    setElapsedTime(0);
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      <div className="slideshow-container">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className={`slide ${index === currentImageIndex ? "active" : ""}`}
          />
        ))}
        <div className="arrows">
          <div>
            <img
              src={Circle1}
              alt="Ardennen"
              className={`ardennen ${currentImageIndex === 1 ? "active" : ""}`}
            />
            <img
              src={Arrow1}
              alt="Westfront"
              className={`westfront-arrow-left ${
                currentImageIndex === 1 ? "active" : ""
              }`}
            />
            <img
              src={Arrow3}
              alt="Ostfront"
              className={`eastfront-arrow-right ${
                currentImageIndex === 1 ? "active" : ""
              }`}
            />
            <img
              src={Arrow3}
              alt="Ostfront 2"
              className={`eastfront-arrow-right-2 ${
                currentImageIndex === 2 ? "active" : ""
              }`}
            />
            <img
              src={Arrow3}
              alt="Ostfront 3"
              className={`eastfront-arrow-right-3 ${
                currentImageIndex === 3 ? "active" : ""
              }`}
            />
            <img
              src={Arrow3}
              alt="Westfront 2"
              className={`westfront-arrow-left-2 ${
                currentImageIndex === 4 ? "active" : ""
              }`}
            />
            <img
              src={Arrow3}
              alt="Westfront 3"
              className={`westfront-arrow-left-3 ${
                currentImageIndex === 5 ? "active" : ""
              }`}
            />
            <img
              src={Arrow1}
              alt="Ostfront 4"
              className={`eastfront-arrow-right-4 ${
                currentImageIndex === 5 ? "active" : ""
              }`}
            />
            <img
              src={Arrow1}
              alt="Ostfront 5"
              className={`eastfront-arrow-right-5 ${
                currentImageIndex === 5 ? "active" : ""
              }`}
            />
            <img
              src={Arrow3}
              alt="Ostfront 6"
              className={`eastfront-arrow-right-6 ${
                currentImageIndex === 6 ? "active" : ""
              }`}
            />
            <img
              src={Arrow3}
              alt="Westfront 4"
              className={`westfront-arrow-left-4 ${
                currentImageIndex === 6 ? "active" : ""
              }`}
            />
          </div>
        </div>
        <div className="progress-bar-container">
          <div
            className="progress-bar"
            style={{ width: `${(elapsedTime / SLIDE_DURATION) * 100}%` }}
          ></div>
        </div>
        <div className="controls">
          <div onClick={goToPreviousSlide}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24px"
              height="24px"
              style={{ transform: "rotate(180deg)" }}
            >
              <path
                d="M10 6l6 6-6 6 1.41 1.41L18.83 12l-7.42-7.41z"
                fill="rgba(82, 112, 107, 0.9)"
              />
            </svg>
          </div>
          <div onClick={togglePlayPause}>
            {isPlaying ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
              >
                <path
                  d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"
                  fill="rgba(82, 112, 107, 0.9)"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
              >
                <path d="M8 5v14l11-7L8 5z" fill="rgba(82, 112, 107, 0.9)" />
              </svg>
            )}
          </div>
          <div onClick={goToNextSlide}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24px"
              height="24px"
            >
              <path
                d="M10 6l6 6-6 6 1.41 1.41L18.83 12l-7.42-7.41z"
                fill="rgba(82, 112, 107, 0.9)"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="slideshow-label-container">
        {labels.map((label, index) => (
          <div
            className={`label ${index === currentImageIndex ? "active" : ""}`}
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;

// Beispielhafte Nutzung der Komponente
// import Slideshow from './Slideshow';
// const images = ["image1.jpg", "image2.jpg", "image3.jpg", "image4.jpg", "image5.jpg", "image6.jpg", "image7.jpg", "image8.jpg"];
// <Slideshow images={images} />;
