import { useEffect, useState } from "react";
import * as edjsHTML from "editorjs-html";
import Slideshow from "../Slideshow/Slideshow";

import Map1 from "../../assets/map_1.jpg";
import Map2 from "../../assets/map_2.jpg";
import Map3 from "../../assets/map_3.jpg";
import Map4 from "../../assets/map_4.jpg";
import Map5 from "../../assets/map_5.jpg";
import Map6 from "../../assets/map_6.jpg";
import Map7 from "../../assets/map_7.jpg";
import Map8 from "../../assets/map_8.jpg";

const edjsParser = edjsHTML();

function Vision() {
  const [content, setContent] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetch(
      `${
        process.env.NODE_ENV === "production" ? "" : "http://localhost:3011"
      }/content-vision`
    )
      .then((res) => res.json())
      .then((data) => {
        const rawData = data?.data?.attributes?.content;
        const parsedData = JSON.parse(rawData);
        if (parsedData) {
          const html = edjsParser.parse(parsedData);
          setContent(
            html.filter((block) => typeof block === "string").join("")
          );
        }

        setLoading(false);
      });
  }, []);

  return (
    <div className="container">
      <div className="Vision-main">
        <div className="slideshow-area">
          <Slideshow
            images={[Map1, Map2, Map3, Map4, Map5, Map6, Map7, Map8]}
            labels={[
              "Europa im Juni 1944 kurz vor dem D-Day in der Normandie",
              "Deutsche Gegenoffensive scheitert im Dezember 1944 in den Ardennen",
              "Im Januar 1945 Angriff der Roten Armee auf einer 1.200 km breiten Frontlinie",
              "An der Oder sammelt sich im März 1945 die Rote Armee für den Angriff auf Berlin",
              "Ebenso im März 1945 überschreiten US Truppen den Rhein bei Remagen",
              "Zusammenbruch der Westfront und Schlacht um Berlin im April 1945",
              "8. Mai 1945: Kapitulation und Vorrücken der Alliierten bis zur Demarkationslinie",
              "Bis Ende Mai 1945 schließen US Armee und Rote Armee an der Demarkationslinie auf",
            ]}
          />
        </div>
        {!isLoading ? (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        ) : null}
      </div>
    </div>
  );
}

export default Vision;
